import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReservationMenu from "../layout/ReservationMenu";
import { sidebarSkeleton } from ".././Skeleton/Skeleton";
import { useTranslation } from "react-i18next";
import {
  ListItemButton,
  List,
  Box,
  ListItemText,
  Button,
  TextField,
} from "@mui/material";

// context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";

// functions
import { _t, getCookie } from "../../../../../functions/Functions";

// base url
import { BASE_URL } from "../../../../../BaseUrl";

// axios
import axios from "axios";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  borderRadius: "10px",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AddSchedule = () => {
  const { t } = useTranslation();

  // loading
  const [loading, setLoading] = useState(false);

  // all schedule
  const [schedule, setSchedule] = useState([]);

  // single schedule
  const [singleschedule, setsingleSchedule] = useState("");

  // get all time option
  const getAllSchedule = () => {
    setLoading(true);
    const scheduleUrl = BASE_URL + `/settings/get-all-schedule`;
    return axios
      .get(scheduleUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setSchedule(res.data);
        setLoading(false);
      })
      .catch((res) => {
        console.log(res);
        setLoading(false);
      });
  };

  // get all time option
  const handleSubmit = () => {
    setLoading(true);
    const scheduleUrl = BASE_URL + `/settings/add-schedule`;
    const formData = {
      time: singleschedule,
    };
    return axios
      .post(scheduleUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setSchedule(res.data);
        setLoading(false);
      })
      .catch((res) => {
        console.log(res);
        setLoading(false);
      });
  };

  // useEffect
  useEffect(() => {
    getAllSchedule();
  }, []);

  return (
    <>
      {/* wraper */}
      <div className="reservation_wrapper pt-3">
        {/* left side reservation menu */}
        <ReservationMenu />
        {/* right side reservation content*/}
        <div className="rsv_right_content">
          {/* top header-bar */}
          <div className="top-header d-flex align-items-center justify-content-between">
            {/* <div className="date-wrapper">
              <span className="icon">
                <i class="fas fa-calendar-day"></i>
              </span>
              <span className="day">
                {TodayDate}
              </span>
            </div> */}
            <div>
              <Link
                to="/dashboard/reservation/add-party"
                className="btn btn-primary text-capitalize"
              >
                add party
              </Link>
            </div>
          </div>
          {/* bottom content */}
          <div className="rsv_btm_content container h-100 w-100">
            <div className="row w-100 justify-content-center align-items-center">
              <div className="col-md-8">
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                    padding: "20px",
                  }}
                >
                  <h4 className="px-4 text-center">Schedule time</h4>
                  <List component="nav">
                    {schedule &&
                      schedule.map((item, index) => {
                        return (
                          <div
                            className="d-flex justify-content-center align-items-center px-5"
                            key={index}
                          >
                            <ListItemText primary={`${item.time}`} />
                            <ListItemButton
                              sx={{
                                width: "20px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                              //   selected={selectedIndex === 2}
                              //   onClick={(event) => handleListItemClick(event, 2)}
                            >
                              <i class="fas fa-trash"></i>
                            </ListItemButton>
                          </div>
                        );
                      })}
                  </List>
                </Box>
              </div>
              <div className="col-md-4">
                <form onSubmit={handleSubmit}>
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Add schedule time"
                    multiline
                    maxRows={4}
                    onChange={(e) => {
                      setsingleSchedule(e.target.value);
                    }}
                  />
                  <Button className="mt-4" variant="contained" type="submit">
                    add schedule time
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSchedule;
