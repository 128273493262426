import React, { useEffect, useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  pageLoading,
  paginationLoading,
  pagination,
  showingData,
  searchedShowingData,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import Moment from "react-moment";

import nodataImg from "./img/no_data.svg";
import loadingImg from "./img/loading.svg";

const CustomerDebtBook = () => {
  // Get the userId param from the URL.
  let { id } = useParams();

  const {
    authUserInfo,
    //pagination
    dataPaginating,
  } = useContext(UserContext);

  // debtors states
  const [debtors, setDebtors] = useState([]);
  const [remainingBalance, setRemainingBalance] = useState(null);
  const [amountToDeduct, setAmountToDeduct] = useState(null);
  const [debtorsHistory, setDebtorsHistory] = useState();

  // loading
  const [loading, setLoading] = useState();

  const {
    //branch
    branchForSearch,
  } = useContext(RestaurantContext);

  const { t } = useTranslation();

  // get debtors info
  const getDebtorsinfo = () => {
    setLoading(true);
    let url = BASE_URL + `/settings/get-debtor-info/${id}`;
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setDebtors(res.data.due);
        setDebtorsHistory(res.data.history);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // update balance
  const updateBalance = (e) => {
    e.preventDefault();
    setLoading(true);
    let url = BASE_URL + `/settings/update-debtor-balance/${id}`;
    let formData = new FormData();
    formData.append("balance", amountToDeduct);
    axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setDebtors(res.data.due);
        setDebtorsHistory(res.data.history);
        setLoading(false);
        toast.success(`${_t(t("Balance updated successfully!!"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error(`${_t(t("Something went wrong!!"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  // handleOnchangeBalance
  const handleOnchangeBalance = (e) => {
    setAmountToDeduct(e.target.value);
    // set remaining balance
    // let remaining =
    //   JSON.parseFloat(debtors.balance) - JSON.parseFloat(amountToDeduct);
    // setRemainingBalance(remaining);
  };

  useEffect(() => {
    getDebtorsinfo();
  }, []);

  return (
    <>
      <Helmet>
        <title>{_t(t("Customers"))}</title>
      </Helmet>

      {/* modal start */}
      <div className="modal fade" id="addDebitModal" aria-hidden="true">
        <form onSubmit={updateBalance}>
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">
                  {debtors && debtors.name}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div class="modal-body">
                {/* balance due */}
                <label for="debitAmount" className="form-label">
                  Balance Due
                </label>
                <input
                  id="debitAmount"
                  className="form-control"
                  type="text"
                  disabled
                  value={debtors.balance}
                />
                {/* enter amount */}
                <label for="debitAmount" className="form-label">
                  Enter the amount
                </label>
                <input
                  id="debitAmount"
                  className="form-control"
                  type="text"
                  onChange={handleOnchangeBalance}
                  placeholder="amount"
                />

                {/* remaining balance */}
                {/* <label for="debitAmount" className="form-label">
                remaing balance
              </label>
              <input
                id="debitAmount"
                className="form-control"
                type="text"
                disabled
                value={remainingBalance}
              /> */}
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-primary">
                  Update
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* modal end */}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            <div className="col-12 t-mb-30 mb-lg-0">
              <div className="t-bg-white ">
                {/* spin loading ends */}
                <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                  <div className="col-12 t-mb-15">
                    <ul className="d-flex fk-breadcrumb justify-content-between mt-4 t-list">
                      <li className="fk-breadcrumb__list">
                        <span className="t-link fk-breadcrumb__link text-capitalize">
                          Customer debit book
                        </span>
                      </li>
                      <li className="d-flex">
                        <Link
                          className="btn btn-secondary sm-text text-uppercase mr-4"
                          to="/dashboard/customers"
                        >
                          {_t(t("back to list"))}
                        </Link>

                        {debtorsHistory && debtorsHistory.length > 0 ? (
                          <button
                            className="btn btn-secondary sm-text text-uppercase"
                            type="button"
                            data-toggle="modal"
                            data-target="#addDebitModal"
                            onClick={() => {}}
                          >
                            {_t(t("action"))}
                          </button>
                        ) : (
                          ""
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="fk-scroll--order-history" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {loading === true
                      ? [
                          <div className="row justify-content-center">
                            <div className="col-lg-8">
                              <div className="mt-5 text-capitalize align-middle text-center">
                                <img src={loadingImg} width={300} alt="" />
                                <h4>Loading...</h4>
                              </div>
                            </div>
                          </div>,
                        ]
                      : [
                          debtorsHistory && debtorsHistory.length > 0
                            ? [
                                <div className="table-responsive">
                                  <table className="table table-bordered table-hover min-table-height mt-4">
                                    <thead className="align-middle">
                                      <tr>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("S/L"))}
                                        </th>

                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Name"))}
                                        </th>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Date"))}
                                        </th>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Debit"))}
                                        </th>

                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Credit"))}
                                        </th>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Balance"))}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="align-middle">
                                      {debtorsHistory &&
                                        debtorsHistory.map((item, index) => {
                                          return (
                                            <>
                                              <tr
                                                className={`${
                                                  index === 0
                                                    ? "bg-dark text-white"
                                                    : ""
                                                }`}
                                                key={item.id}
                                              >
                                                <td
                                                  className={`sm-text text-capitalize align-middle text-center ${
                                                    index === 0
                                                      ? "font-weight-bold"
                                                      : "xsm-text"
                                                  }`}
                                                >
                                                  {1 + index}
                                                </td>
                                                <td
                                                  className={`sm-text text-capitalize align-middle text-center ${
                                                    index === 0
                                                      ? "font-weight-bold"
                                                      : "xsm-text"
                                                  }`}
                                                >
                                                  {item.name}
                                                </td>
                                                <td
                                                  className={`sm-text text-capitalize align-middle text-center ${
                                                    index === 0
                                                      ? "font-weight-bold"
                                                      : "xsm-text"
                                                  }`}
                                                >
                                                  <Moment format="YYYY-MM-DD">
                                                    {item.created_at}
                                                  </Moment>
                                                </td>
                                                <td
                                                  className={`sm-text text-capitalize align-middle text-center ${
                                                    index === 0
                                                      ? "font-weight-bold"
                                                      : "xsm-text"
                                                  }`}
                                                >
                                                  {item.debit === null
                                                    ? "-"
                                                    : item.debit}
                                                </td>
                                                <td
                                                  className={`sm-text text-capitalize align-middle text-center ${
                                                    index === 0
                                                      ? "font-weight-bold"
                                                      : "xsm-text"
                                                  }`}
                                                >
                                                  {item.credit === null
                                                    ? "-"
                                                    : item.credit}
                                                </td>
                                                <td
                                                  className={`sm-text text-capitalize align-middle text-center ${
                                                    index === 0
                                                      ? "font-weight-bold"
                                                      : "xsm-text"
                                                  }`}
                                                >
                                                  {item.balance}
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        })}
                                    </tbody>
                                  </table>
                                </div>,
                              ]
                            : [
                                <div className="row justify-content-center">
                                  <div className="col-lg-8">
                                    <div className="mt-5 text-capitalize align-middle text-center">
                                      <img src={nodataImg} width={300} alt="" />
                                      <h4>no data found</h4>
                                    </div>
                                  </div>
                                </div>,
                              ],
                        ]}
                  </div>
                </div>
              </div>
              {/* )} */},
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CustomerDebtBook;
