import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../../../../BaseUrl";
import { _t, getCookie } from "../../../../../functions/Functions";
import axios from "axios";
import { toast } from "react-toastify";
import { isYearAndMonthViews } from "@mui/x-date-pickers/DatePicker/shared";

//context consumer
// import { UserContext } from "../../../../../contexts/User";
// import { UserContext } from "../../../../../contexts/User";

const SingleSectionAssignTable = () => {
  let { id } = useParams();
  const { t } = useTranslation();
  const [showAssign, setshowAssign] = useState();
  const [showNotify, setshowNotify] = useState(false);
  const [showCheck, setshowCheck] = useState(false);
  const [allTables, setallTables] = useState(null);
  const [assignTable, setassignTable] = useState([]);
  const [assignTableToCustomer, setassignTableToCustomer] = useState([]);
  const [loading, setLoading] = useState(false);

  // filter table unassigned list
  const unaasignedTables =
    allTables &&
    allTables.filter((table) => table.assigned_to_section === "no");

  // handle modal show options
  const handleShowOptions = (e) => {
    if (e === "assign") {
      setshowAssign(true);
      setshowNotify(false);
      setshowCheck(false);
    } else if (e === "notify") {
      setshowAssign(false);
      setshowNotify(true);
      setshowCheck(false);
    } else if (e === "check") {
      setshowAssign(false);
      setshowNotify(false);
      setshowCheck(true);
    }
  };

  // handle add table
  const handlAddTable = (table) => {
    setassignTableToCustomer([...assignTableToCustomer, table]);
  };

  // handle table booked alert open
  const handleBookedTableOpen = () => {
    toast.info(`${_t(t("This table is already booked!!"))}`, {
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    });
  };

  // handle remove table
  const handleTableRemove = (table) => {
    let oldTable = assignTableToCustomer.filter(
      (mainTable) => mainTable.id != table.id
    );

    setassignTableToCustomer(oldTable);
    // console.log("remove table ", oldTable);
  };

  // get all tables
  const getAllTables = () => {
    setLoading(true);
    const tableUrl = BASE_URL + `/settings/all-table`;
    return axios
      .get(tableUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setallTables(res.data[1]);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // handletables
  const handletables = (id) => {
    setassignTable(id);
  };

  // get all assigned section tables
  const getAllAssignedSectionTables = () => {
    setLoading(true);
    const assignedUrl = BASE_URL + `/settings/assigned-tables-to-section/${id}`;
    return axios
      .get(assignedUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setassignTableToCustomer(res.data);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect
  useEffect(() => {
    getAllTables();
    getAllAssignedSectionTables();
  }, []);

  // send data to backend
  const submitAssignTable = (e) => {
    e.preventDefault();

    const addUrl = BASE_URL + `/settings/assign-tables-to-section/${id}`;

    let tableIds = assignTableToCustomer.map((item) => {
      return item.id;
    });

    const formData = {
      table_group_id: tableIds,
    };

    return axios
      .post(addUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        // console.log(res);
        setLoading(false);
        setassignTable([]);
        toast.success(`${_t(t("Table assigned successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch((err) => {
        toast.error(`${_t(t("Something went wrong!!"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  return (
    <>
      {/* wraper */}
      <form onSubmit={submitAssignTable}>
        <div className="reservation_wrapper pt-3">
          <div className="rsv_right_content w-100">
            {/* top header-bar */}
            <div className="top-header d-flex flex-wrapp align-items-center justify-content-between">
              <div className="d-flex flex-wrap">
                {assignTableToCustomer.length > 0
                  ? [
                      assignTableToCustomer.map((table) => {
                        return (
                          <>
                            <div className="btn border border-2 rounded py-1 px-3 d-inline-block m-2 d-flex align-items-center text-white">
                              {table.name}
                              <span
                                className="d-inline-block ml-4 text-danger"
                                onClick={() => handleTableRemove(table)}
                              >
                                <i className="fas fa-times"></i>
                              </span>
                            </div>
                          </>
                        );
                      }),
                    ]
                  : ""}
                {/* {assignTableToCustomer.length > 0 ? (
                  <button
                    className="btn btn-success text-capitalize"
                    type="submit"
                  >
                    save
                  </button>
                ) : (
                  ""
                )} */}
                <button
                  className="btn btn-success text-capitalize  m-2"
                  type="submit"
                >
                  save
                </button>
              </div>

              {/* back to list */}
              <Link
                to="/dashboard/reservation/section"
                className="btn btn-primary text-capitalize"
              >
                back to the sections
              </Link>
            </div>
            {/* bottom content */}
            <div className="rsv_btm_content">
              {/* inner right side */}
              <div className="rsv_right_table_plan_wrapper overflow-y-scroll w-100 height-100vh">
                {allTables &&
                  allTables.map((table, index) => {
                    return (
                      <>
                        <div
                          onClick={(e) => {
                            table.assigned_to_section === "yes"
                              ? handleBookedTableOpen()
                              : handlAddTable(table);
                          }}
                          className={`single-table-layout mt-4 
                       ${
                         table.capacity > 10
                           ? "large-table"
                           : table.capacity > 6
                           ? "traingle-table mx-4"
                           : "circle-table"
                       }
                       ${table.assigned_to_section === "yes" ? "booked" : ""}
                       m-1`}
                          key={index}
                        >
                          <span className="title">{table.name}</span>
                          <span className="d-inline-block text-white">
                            {table.capacity}
                          </span>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default SingleSectionAssignTable;
