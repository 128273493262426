import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import { _t, getCookie, pageLoading } from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL, demo } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FlutterwaveSetup = () => {
  const { t } = useTranslation();
  const history = useHistory();

  // hooks
  const [flutterwaveDetails, setflutterwaveDetails] = useState({
    publicKey: null,
    secretKey: null,
    fw_currency_code: null,
  });

  // loading
  const [loading, setloading] = useState(true);

  // checkbox state
  const [isChecked, setIsChecked] = useState(false);

  // get flutterwave info
  const getFwInfo = () => {
    setloading(true);
    const url = BASE_URL + `/settings/get-flutterwave-info`;
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setflutterwaveDetails({
          ...flutterwaveDetails,
          publicKey: res.data.FLUTTERWAVE_PUBLIC_KEY,
          secretKey: res.data.FLUTTERWAVE_SECRET_KEY,
          fw_currency_code: res.data.FLUTTERWAVE_CURRENCY_CODE,
        });

        if (res.data.FLUTTERWAVE_SHOW == "YES") {
          setIsChecked(true);
        } else {
          setIsChecked(false);
        }
        setloading(false);
      });
  };

  const Country_code_list = [
    {
      country: "Argentine Peso",
      code: "ARS",
    },
    {
      country: "Brazilian Real",
      code: "BRL",
    },
    {
      country: "British Pound Sterling",
      code: "GBP",
    },
    {
      country: "Canadian Dollar",
      code: "CAD",
    },
    {
      country: "Cape Verdean Escudo",
      code: "CVE",
    },
    {
      country: "Chilean Peso",
      code: "CLP",
    },
    {
      country: "Congolese France",
      code: "CDF",
    },
    {
      country: "Egyptian Pound",
      code: "EGP",
    },
    {
      country: "SEPA",
      code: "EUR",
    },
    {
      country: "Gambian Dalasi",
      code: "GMD",
    },
    {
      country: "Ghanaian Cedi",
      code: "GHS",
    },
    {
      country: "Guinean Franc",
      code: "GNF",
    },
    {
      country: "Kenyan Shilling",
      code: "KES",
    },
    {
      country: "Liberian Dollar",
      code: "LRD",
    },
    {
      country: "Malawian Kwacha",
      code: "MWK",
    },
    {
      country: "Mexican Peso",
      code: "MXN",
    },
    {
      country: "Moroccan Dirham",
      code: "MAD",
    },
    {
      country: "Mozambican Metical",
      code: "MZN",
    },
    {
      country: "Nigerian Naira",
      code: "NGN",
    },
    {
      country: "Peruvian Sol",
      code: "SOL",
    },
    {
      country: "Rwandan Franc",
      code: "RWF",
    },
    {
      country: "Sierra Leonean Leone",
      code: "SLL",
    },
    {
      country: "São Tomé and Príncipe dobra",
      code: "STD",
    },
    {
      country: "South African Rand",
      code: "ZAR",
    },
    {
      country: "Tanzanian Shilling",
      code: "TZS",
    },
    {
      country: "Ugandan Shilling",
      code: "UGX",
    },
    {
      country: "Ugandan Shilling",
      code: "UGX",
    },
    {
      country: "United States Dollar",
      code: "USD",
    },
    {
      country: "Central African CFA Franc BEAC",
      code: "XAF",
    },
    {
      country: "West African CFA Franc BCEAO",
      code: "XOF",
    },
    {
      country: "Zambian Kwacha",
      code: "ZMW",
    },
  ];

  //handle Set country code hook
  const handleSetCountryCode = (selectedCountry) => {
    setflutterwaveDetails({
      ...flutterwaveDetails,
      fw_currency_code: selectedCountry,
    });
  };

  //useEffect == componentDidMount
  useEffect(() => {
    getFwInfo();
  }, []);

  // onchange
  const handleOnchange = (e) => {
    setflutterwaveDetails({
      ...flutterwaveDetails,
      [e.target.name]: e.target.value,
    });
  };

  // handle checkbox
  const handleCheckbox = (e) => {
    setIsChecked(e.target.checked);
  };

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();

    if (demo == "YES") {
      toast.success(`${_t(t("Sorry,This is demo purpose only"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    } else {
      const url = BASE_URL + `/settings/setup-flutterwave`;
      let formData = new FormData();
      formData.append("publicKey", flutterwaveDetails.publicKey);
      formData.append("secretKey", flutterwaveDetails.secretKey);
      formData.append(
        "currency_code",
        flutterwaveDetails.fw_currency_code.code
      );
      formData.append("show", isChecked);
      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          toast.success(`${_t(t("Flutterwave setup successfully"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Flutterwave"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white p-3 p-md-5">
                <div>
                  <h4 className="text-secodary my-4">Setup Flutterwave</h4>
                </div>
                <hr />

                {loading == true ? (
                  pageLoading()
                ) : (
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="fwsecretkey" className="form-label">
                        Secret Key
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="fwsecretkey"
                        onChange={handleOnchange}
                        defaultValue={flutterwaveDetails.secretKey}
                        name="secretKey"
                        placeholder="sk_test_51H2XGOKJQvOIJEOiu"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="fwpublicKey" className="form-label">
                        Public key
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="fwpublicKey"
                        onChange={handleOnchange}
                        name="publicKey"
                        defaultValue={flutterwaveDetails.publicKey}
                        placeholder="pk_test_51H2XGOKJQvOIJEOikQ62q1vuUW"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="fwpublicKey" className="form-label">
                        country list
                      </label>
                      <Select
                        value={Country_code_list.filter(
                          (option) =>
                            option.code ===
                              flutterwaveDetails.fw_currency_code &&
                            flutterwaveDetails.fw_currency_code
                        )}
                        options={Country_code_list}
                        components={makeAnimated()}
                        getOptionLabel={(option) => option.country}
                        getOptionValue={(option) => option.code}
                        classNamePrefix="select"
                        onChange={handleSetCountryCode}
                        maxMenuHeight="200px"
                        placeholder={
                          _t(t("Please select a country code")) + ".."
                        }
                      />
                    </div>

                    <div className="mb-3">
                      <input
                        type="checkbox"
                        id="fwcheckbox"
                        defaultValue={isChecked}
                        onChange={handleCheckbox}
                        checked={isChecked}
                      />
                      <label htmlFor="fwcheckbox" className="form-label ml-4">
                        hide/show
                      </label>
                    </div>
                    <div className="mb-3">
                      <button className="btn btn-primary text-capitalize">
                        setup
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default FlutterwaveSetup;
