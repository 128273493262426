import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
    _t,
    getCookie,
    pageLoading
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL, demo } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StripeCardList = () => {

    const { t } = useTranslation();
    const history = useHistory();


    // hooks
    const [stripeCardReader, setstripeCardReader] = useState([]);

    // loading
    const [loading, setloading] = useState(true);

    // list all reader
    const listAllReaderData = () => {
        setloading(true);
        const url = BASE_URL + `/settings/list-all-connected-readers`;
        axios.get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
        }).then((res) => {
            setstripeCardReader(res.data);
            console.log(res);
            setloading(false);
        });
    }

    useEffect(() => {
        listAllReaderData();
    }, []);


    return (
        <>
            <Helmet>
                <title>{_t(t("Stripe card list"))}</title>
            </Helmet>

            {/* main body */}
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <ManageSidebar />
                        </div>
                        {/* left Sidebar ends */}

                        {/* Rightbar contents */}
                        <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
                            <div className="t-bg-white p-3 p-md-5">
                                <div>
                                    <h4 className="text-secodary my-4">Stripe Card Reader List</h4>
                                </div>
                                <hr />

                                {loading == true ? pageLoading() :
                                    <>

                                        {
                                            stripeCardReader.length > 0 ? [
                                                stripeCardReader.map((item, index) => {
                                                    return <ul>
                                                        <li key={index}>{item.device_type}</li>
                                                        <li key={index}>{item.id}</li>
                                                    </ul>
                                                })
                                            ] : [
                                                <h5>No Card found</h5>
                                            ]

                                        }

                                    </>
                                }

                            </div>
                        </div>
                        {/* Rightbar contents end*/}
                    </div>
                </div>
            </main>
            {/* main body ends */}
        </>
    );
};

export default StripeCardList;
