import React, { useEffect, useState } from "react";
import axios from "axios";
import { _t, getCookie } from "../../../../../functions/Functions";
import { BASE_URL } from "../../../../../BaseUrl";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const BookedTable = () => {
  const { t } = useTranslation();

  //   usestate all booked tables
  const [allBookedTables, setBookedTables] = useState(["helo world"]);

  //   usetate loading
  const [loading, setLoading] = useState(false);

  //  get all booked tables
  const getAllBookedTables = () => {
    setLoading(true);
    const todayUrl = BASE_URL + `/settings/get-booked-tables`;
    return axios
      .get(todayUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setBookedTables(res.data);
        console.log(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      }); // end of axios
  };

  // call usefffect
  useEffect(() => {
    getAllBookedTables();
  }, []);

  return (
    <div className="rsv_right_table_plan_wrapper overflow-y-scroll w-100 height-100vh">
      {allBookedTables &&
        allBookedTables.map((table, index) => {
          return (
            <>
              <div
                className={`single-table-layout mt-4 
                ${
                  table.capacity > 10
                    ? "large-table"
                    : table.capacity > 6
                    ? "traingle-table mx-4"
                    : "circle-table"
                }
                ${table.status === "booked" ? "booked" : ""}
                m-1`}
                key={index}
              >
                <span className="title">{table.name}</span>
                <span className="d-inline-block text-white">
                  {table.capacity}
                </span>
              </div>
            </>
          );
        })}

      {allBookedTables.length === 0 ? [<h4>No seated table found</h4>] : ""}
    </div>
  );
};

export default BookedTable;
