import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../../BaseUrl";
import { getCookie } from "../../../../functions/Functions";
import ReservationMenu from "./layout/ReservationMenu";

const ReservationList = () => {

  //   usestate all booked tables
  const [reservedList, setreservedList] = useState(["helo world"]);

  //   usetate loading
  const [loading, setLoading] = useState(false);

  //  get all booked tables
  const getreservedList = () => {
    setLoading(true);
    const todayUrl = BASE_URL + `/settings/get-reserved-history`;
    return axios
      .get(todayUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setreservedList(res.data);
        console.log(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      }); // end of axios
  };

  // call usefffect
  useEffect(() => {
    getreservedList();
  }, []);

  return (
    // wraper
    <div className="reservation_wrapper pt-3">
      {/* reservation menu */}
      <ReservationMenu />
      {/* right side reservation content*/}
      <div className="rsv_right_content">
        {/* top header-bar */}
        <div className="top-header d-flex align-items-center justify-content-between">
          <div className="date-wrapper">
            <span className="icon">
              <i class="fas fa-calendar-day"></i>
            </span>
            <span className="day">Tuesday, May 2022</span>
          </div>
          <div>
            <Link
              to="/dashboard/reservation/add-party"
              className="btn btn-primary text-capitalize  fz-12"
            >
              add party
            </Link>
          </div>
        </div>
        {/* bottom content */}
        <div className="align-content-center d-flex justify-content-center p-4 rsv_btm_content">
          {/* inner left side */}

          {/* inner right side */}

          <div className="table-responsive w-75">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  {/* <th scope="col">Branch</th> */}
                  <th scope="col">Date</th>
                </tr>
              </thead>
              <tbody>
                {reservedList && reservedList.map((list,index)=>(
                <tr key={`reserved_list${list.id}`}>
                  <th scope="row">{index+1}</th>
                  <td>{list.name}</td>
                  <td>{list.email}</td>
                  <td>{list.phone}</td>
                  <td>{list.date}</td>
                
                </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservationList;
