import React, { useContext } from 'react';
import { BASE_URL } from '../../BaseUrl';
//functions
import {
    _t,
    getSystemSettings,
    pageLoading
} from "../../../src/functions/Functions";

//   context value
import { SettingsContext, loading, setLoading } from "../../contexts/Settings";
const Countdown = () => {
    //getting context values here
    let { templateOneSettings, loading } =
        useContext(SettingsContext);
    return (
        <>
            <section className="countdown-area padding-top-120 padding-bottom-120">
                <div className="container">
                    <div className="countdown-shapes">
                        <span className="cs-1 item-bounce"><img src="/website/images/shapes/24.png" alt="" /></span>
                        <span className="cs-3 item-bounce"><img src="/website/images/shapes/26.png" alt="" /></span>
                        <span className="cs-4 item-animateOne"><img src="/website/images/shapes/27.png" alt="" /></span>
                        <span className="cs-5"><img src="/website/images/shapes/18.png" alt="" /></span>
                        <span className="cs-6"><img src="/website/images/shapes/22.png" alt="" /></span>
                        <span className="cs-7"><img src="/website/images/shapes/30.png" alt="" /></span>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12 margin-bottom-20">
                            <div className="countdown-left">
                                <span className="cs-1"><img src="/website/images/shapes/25.png" alt="" /></span>
                                <span className="cs-2"><img src="/website/images/shapes/Leaf.png" alt="" /></span>
                                <span className="cs-3"><img src="/website/images/shapes/Leaf4.png" alt="" /></span>
                                <span className="cs-4"><img src="/website/images/img/3.png" alt="" /></span>
                                <span className="cs-5"><img src="/website/images/shapes/tomato.png" alt="" /></span>
                                <span className="cs-6"><img src="/website/images/shapes/onions.png" alt="" /></span>
                                <span className="cs-7"><img src="/website/images/shapes/Leaf2.png" alt="" /></span>
                                <span className="cs-8"><img src="/website/images/shapes/Leaf3.png" alt="" /></span>
                                {/* <img src="/website/images/img/21.png" alt="" /> */}
                                <img src={`${BASE_URL}/uploads/${getSystemSettings(templateOneSettings, "feaImage")}`} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1 col-md-12 col-sm-12 col-12">
                            <div className="countdown-right">
                                <div className="common-title-area padding-bottom-20">
                                    {/* <h3>coming soon</h3> */}
                                    <h3>{getSystemSettings(templateOneSettings, "feaFancyTitle")}</h3>
                                    {/* <h2>Spicy Chicken
                                        Pizza <span>Food </span> </h2> */}
                                    <h2>
                                        {getSystemSettings(templateOneSettings, "feaTitle")}
                                    </h2>
                                    {/* <p>feel hunger! order your favourite food.</p> */}
                                    <p> {getSystemSettings(templateOneSettings, "feaContent")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Countdown;
