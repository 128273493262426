import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AssignTable from "./component/AssignTable";
import BookedTable from "./component/BookedTable";
import ReservationMenu from "./layout/ReservationMenu";
const Seated = () => {
  // hidefooter
  // const hideFooter = () => {
  //   let reservationPanels = window.location.pathname.includes("reservation");
  //   let footerId = document.getElementById("footer");
  //   reservationPanels
  //     ? footerId.classList.add("d-none")
  //     : footerId.classList.remove("d-none");
  // };

  const [showAssign, setshowAssign] = useState();
  const [showNotify, setshowNotify] = useState(false);
  const [showCheck, setshowCheck] = useState(false);

  const handleShowOptions = (e) => {
    if (e === "assign") {
      setshowAssign(true);
      setshowNotify(false);
      setshowCheck(false);
    } else if (e === "notify") {
      setshowAssign(false);
      setshowNotify(true);
      setshowCheck(false);
    } else if (e === "check") {
      setshowAssign(false);
      setshowNotify(false);
      setshowCheck(true);
    }
  };

  // useEffect
  useEffect(() => {
    setshowAssign(true);
    // hideFooter();
  }, []);

  return (
    // wraper
    <div className="reservation_wrapper pt-3">
      {/* reservation menu */}
      <ReservationMenu />
      {/* right side reservation content*/}
      <div className="rsv_right_content">
        {/* top header-bar */}
        <div className="top-header d-flex align-items-center justify-content-between">
          <div className="date-wrapper">
            <span className="icon">
              <i class="fas fa-calendar-day"></i>
            </span>
            <span className="day">Tuesday, May 2022</span>
          </div>
          <div>
            <Link
              to="/dashboard/reservation/add-party"
              className="btn btn-primary text-capitalize  fz-12"
            >
              add party
            </Link>
          </div>
        </div>
        {/* bottom content */}
        <div className="rsv_btm_content">
          {/* inner left side */}

          {/* inner right side */}
          <BookedTable />
        </div>
      </div>
    </div>
  );
};

export default Seated;
