import React, { useContext } from "react";
import { BASE_URL } from "../../BaseUrl";
//functions
import { _t, getSystemSettings } from "../../../src/functions/Functions";

//   context value
import { SettingsContext, loading, setLoading } from "../../contexts/Settings";

const Banner = () => {
  //getting context values here
  let { templateOneSettings, loading } = useContext(SettingsContext);

  return (
    <>
      <section className="banner-area padding-top-100 padding-bottom-150">
        <div className="banner-shapes">
          <span className="b-shape-1">
            {" "}
            <img src="/website/images/img/5.png" alt="" />
          </span>
          <span className="b-shape-2">
            <img src="/website/images/img/6.png" alt="" />
          </span>
          <span className="b-shape-3">
            <img src="/website/images/img/7.png" alt="" />
          </span>
          <span className="b-shape-4">
            <img src="/website/images/img/9.png" alt="" />
          </span>
          <span className="b-shape-5">
            <img src="/website/images/shapes/18.png" alt="" />
          </span>
        </div>
        <div className="container padding-top-145">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-12 col-lg-6 col-xl-6">
              <div className="banner-content">
                <h1>
                  {getSystemSettings(templateOneSettings, "heroTitle")
                    ? getSystemSettings(templateOneSettings, "heroTitle")
                    : "enjoy our delicious food"}
                </h1>
                {/* <h1>enjoy our delicious <span>food</span></h1> */}
                {/* // <!-- buyone-shape --> */}
                <div className="buyone-shape">
                  <div className="banner-tag">
                    <h6 className="text-white">
                      {getSystemSettings(templateOneSettings, "heroTagTitle")
                        ? getSystemSettings(templateOneSettings, "heroTagTitle")
                        : "Buy One Pizza. Get One"}
                    </h6>
                  </div>
                  {/* <span className="banner-badge">order now</span> */}
                  <span className="banner-badge">
                    {getSystemSettings(templateOneSettings, "heroBadgeTitle")
                      ? getSystemSettings(templateOneSettings, "heroBadgeTitle")
                      : "Buy One Pizza. Get One"}
                  </span>
                </div>

                {/* // <!-- order-box --> */}
                <div className="order-box">
                  <span className="order-img">
                    <img src="/website/images/icons/1.png" alt="" />
                  </span>
                  <div className="order-content">
                    <p>delivery order num.</p>
                    <span>
                      {getSystemSettings(templateOneSettings, "heroNumber")
                        ? getSystemSettings(templateOneSettings, "heroNumber")
                        : "12312300"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-none d-lg-block col-lg-6 col-xl-6">
              <div className="banner-img">
                <div className="pizza-shapes">
                  <span className="p-shape-1">
                    <img src="/website/images/img/2.png" alt="" />
                  </span>
                  <span className="p-shape-2">
                    <img src="/website/images/img/3.png" alt="" />
                  </span>
                  <span className="p-shape-3">
                    <img src="/website/images/img/4.png" alt="" />
                  </span>
                  <span className="p-shape-4">
                    <img src="/website/images/img/8.png" alt="" />
                  </span>
                </div>
                {/* <img src="/website/images/img/1.png" alt="" /> */}
                <img
                  src={
                    getSystemSettings(templateOneSettings, "heroImage")
                      ? `${BASE_URL}/uploads/${getSystemSettings(
                          templateOneSettings,
                          "heroImage"
                        )}`
                      : "/website/images/menu-item/temp-hero2.png"
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
