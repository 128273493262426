import React, { props } from "react";
import { Box, Modal, Button, Typography, TextField } from "@mui/material";
const NewSectionModal = ({
  open,
  handleClose,
  handleAddSection,
  onChangeSection,
  style,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box component="form" onSubmit={handleAddSection} sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Add section
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <TextField
            sx={{ width: "100%" }}
            id="standard-basic"
            label="Section name"
            variant="standard"
            onChange={onChangeSection}
          />
          <Button
            type="submit"
            sx={{
              marginTop: "10px",
              textTransform: "capitalize",
              display: "block",
            }}
            variant="contained"
          >
            done
          </Button>
        </Typography>
      </Box>
    </Modal>
  );
};

export default NewSectionModal;
