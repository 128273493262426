import React, { useContext, useState } from "react";
import { BASE_URL } from "../../BaseUrl";

//functions
import {
  _t,
  getSystemSettings,
  pageLoading,
} from "../../../src/functions/Functions";

//   context value
import { SettingsContext, loading, setLoading } from "../../contexts/Settings";
const About = () => {
  //getting context values here
  let { templateOneSettings, loading } = useContext(SettingsContext);
  // use state here

  return (
    <>
      <div id="myModal" className="modal fade">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body template-one-modal">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  id="cartoonVideo"
                  className="embed-responsive-item"
                  width="560"
                  height="315"
                  src={getSystemSettings(templateOneSettings, "aboutUrl")}
                  // src='https://www.youtube.com/embed/-HgDBmJZB5c'
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* video mdoal end */}
      <section className="about-area padding-top-120">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 wow fadeInLeft">
              <div className="about-left">
                <div className="about-l-shapes">
                  <span className="als-1">
                    <img src="/website/images/shapes/2.png" alt="" />
                  </span>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex align-items-end justify-content-end margin-bottom-20">
                    <div className="about-gallery-1">
                      <img
                        src={
                          getSystemSettings(templateOneSettings, "aboutsmImg")
                            ? `${BASE_URL}/uploads/${getSystemSettings(
                                templateOneSettings,
                                "aboutsmImg"
                              )}`
                            : "/website/images/gallery/1.jpg"
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-8 col-8 margin-bottom-20">
                    <div className="about-gallery-2">
                      {/* <a data-toggle="modal" href="#myModal" className="popup-youtube"
                                            > */}
                      <img
                        src={
                          getSystemSettings(templateOneSettings, "aboutlgImg")
                            ? `${BASE_URL}/uploads/${getSystemSettings(
                                templateOneSettings,
                                "aboutlgImg"
                              )}`
                            : "/website/images/gallery/2.jpg"
                        }
                        alt=""
                      />
                      {/* </a> */}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className="about-gallery-3">
                      {/* <img src="/website/images/gallery/3.jpg" alt="" /> */}
                      <img
                        src={
                          getSystemSettings(templateOneSettings, "aboutmdImg")
                            ? `${BASE_URL}/uploads/${getSystemSettings(
                                templateOneSettings,
                                "aboutmdImg"
                              )}`
                            : "/website/images/gallery/3.jpg"
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-5 col-5 d-flex align-items-start">
                    <div className="about-gallery-4 text-center">
                      <img
                        className="mp"
                        src="/website/images/icons/3.png"
                        alt=""
                      />
                      <div className="items counter">
                        {getSystemSettings(templateOneSettings, "totalItems")
                          ? getSystemSettings(templateOneSettings, "totalItems")
                          : "500"}
                      </div>
                      <p>food item</p>
                      <span className="g-s-4">
                        <img src="/website/images/shapes/10.png" alt="" />
                      </span>
                      <span className="g-s-5">
                        <img src="/website/images/shapes/14.png" alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 offset-lg-1 wow fadeInRight">
              <div className="about-right">
                <div className="about-r-shapes">
                  <span className="as-1 item-bounce">
                    <img src="/website/images/shapes/1.png" alt="" />
                  </span>
                </div>
                <h2>
                  {getSystemSettings(templateOneSettings, "aboutTitle")
                    ? getSystemSettings(templateOneSettings, "aboutTitle")
                    : "fresh taste at a g reat price"}
                </h2>
                <p>
                  {getSystemSettings(templateOneSettings, "aboutContent")
                    ? getSystemSettings(templateOneSettings, "aboutContent")
                    : "Food is a restaurant, bar and coffee roastery located on a busy corner site"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
