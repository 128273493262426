import React, { useState, useEffect, createContext } from "react";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

//functions
import { getCookie, _t } from "../functions/Functions";

// creating context api
const ReservationContext = createContext();

const ReservationProvider = ({ children }) => {
  // states
  const [allTables, setallTables] = useState(null);
  const [loading, setLoading] = useState(false);

  // waitlist
  const [todayList, settodayList] = useState([]);
  const [upcommingList, setupcommingList] = useState([]);

  // table draggable states
  const [tablePositions, settablePositions] = useState({});

  // functions
  // get all tables
  const getAllTables = () => {
    setLoading(true);
    const tableUrl = BASE_URL + `/settings/all-table`;
    return axios
      .get(tableUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => { 
        setallTables(res.data[1]);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // get today reservation list
  const getWaitlistReservations = () => {
    setLoading(true);
    const todayUrl = BASE_URL + `/settings/get-waitlist-reservations`;
    return axios
      .get(todayUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        settodayList(res.data["today"]);
        setupcommingList(res.data["upcomming"]);
        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);
        console.log(res);
        setLoading(false);
      });
  };

  // get table draggale table postion on load
  const getDraggablePostion = () => {
    setLoading(true);
    const existingDivPositions = JSON.parse(
      localStorage.getItem("positions_div")
    );
    settablePositions(existingDivPositions);
    setLoading(false);
  }

  const { t } = useTranslation();

  return (
    <ReservationContext.Provider
      value={{
        // loading
        loading,
        setLoading,

        // tables
        allTables,
        setallTables,

        // waitlist states
        todayList,
        settodayList,
        setupcommingList,
        upcommingList,

        // table draggable states
        tablePositions,
        settablePositions,

        // functions
        getAllTables,
        getWaitlistReservations,
        getDraggablePostion
      }}
    >
      {children}
    </ReservationContext.Provider>
  );
};

export { ReservationContext, ReservationProvider };
