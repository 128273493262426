import React, { useEffect, useContext, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import { useReactToPrint } from "react-to-print";

import { SettingsContext } from "../../../../contexts/Settings";

// loading image
import { loadingImage } from "./image/loading.png";

const Xreport = () => {
  const { t } = useTranslation();
  let { date } = useParams();
  const {
    // //common
    loading,
    setLoading,
    generalSettings,
  } = useContext(SettingsContext);

  // pos order items states
  const [xYreportData, setxYreportData] = useState([]);
  const [reportType, setreportType] = useState('');

  // print report
  const xComponent = useRef();

  // print
  const handleXReportPrint = useReactToPrint({
    content: () => xComponent.current,
  });

  // date information
  let todayDate = new Date(date).toLocaleDateString();

  // check report type
  const checkReportType = () => {
    let paramsDate = new Date(date).getDate();
    let todayDatee = new Date().getDate();

    if (paramsDate < todayDatee) {
      setreportType('Z')
    } else {
      setreportType('X')
    }
  }

  //get pos order items
  const getReportDataOnLoad = () => {
    setLoading(true);
    const url = BASE_URL + `/settings/get-full-report/${date}`;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setxYreportData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };


  useEffect(() => {
    getReportDataOnLoad();
    checkReportType();
  }, []);
  return (
    <>
      <Helmet>
        <title>{_t(t(`${reportType && reportType} report`))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2 justify-content-center">
            {/* Rightbar contents */}
            <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                {loading === true
                  ? [<img src={loadingImage} alt="loading..." />]
                  : [

                    <div
                      className="fk-scroll--pos-menu table-bottom-info-hide"
                      data-simplebar
                    >
                      {/********************************
                      *  report top navigation
                      * ******************************/}
                      <div className="d-flex justify-content-end p-4">
                        <button
                          className="btn btn-primary text-capitalize m-2"
                          onClick={() => handleXReportPrint()}
                        >
                          print
                        </button>
                        <Link
                          className="btn btn-primary text-capitalize m-2"
                          to="/dashboard/work-periods"
                        >
                          back to workperiod
                        </Link>
                      </div>
                      {/* printable componenet wrapper */}
                      <div
                        className="t-pl-15 t-pr-15 col-md-8 mx-auto p-4"
                        ref={xComponent}
                      >
                        {/********************************
                           *  report header
                           * ******************************/}
                        <div className="mb-3">
                          <span className="d-block fk-print-text font-weight-bold text-uppercase text-center sm-text mb-2">
                            {getSystemSettings(generalSettings, "siteName")}
                          </span>
                          <span className="d-block fk-print-text font-weight-bold text-uppercase text-center sm-text mb-2">
                            {getSystemSettings(generalSettings, "address")}
                          </span>
                          <span className="d-block fk-print-text font-weight-bold text-uppercase text-center sm-text mb-2">
                            phone no{" "}
                            {getSystemSettings(generalSettings, "phnNo")}
                          </span>
                          <span className="d-block fk-print-text font-weight-bold text-uppercase text-center sm-text mb-2">
                            {reportType && reportType} report {todayDate}
                          </span>
                        </div>
                        {/********************************
                           * items category
                           * ******************************/}
                        <div className="d-flex justify-content-between">
                          <span className="report-title">Item category</span>
                        </div>
                        <div className="d-flex justify-content-between">
                          {/* items categories name*/}
                          <div className="list-group w-33 text-left">
                            {xYreportData &&
                              xYreportData['categories'] && xYreportData['categories'].map((item, index) => {
                                return (
                                  <div
                                    className="border-0 list-group-item p-0 "
                                    key={index}
                                  >
                                    {item}
                                  </div>
                                );
                              })}
                          </div>
                          {/* categories quantity */}
                          <div className="list-group w-33 text-center">
                            {xYreportData && xYreportData['categories_list_length'] &&
                              xYreportData['categories_list_length'].map(
                                (item, index) => {
                                  return (
                                    <div
                                      className="border-0 list-group-item p-0  text-center "
                                      key={index}
                                    >
                                      {item}
                                    </div>
                                  );
                                }
                              )}
                          </div>
                          {/* items categories price */}
                          <div className="list-group w-33 text-right">
                            {xYreportData && xYreportData['categories_list_price'] &&
                              xYreportData['categories_list_price'].map(
                                (item, index) => {
                                  return (
                                    <div
                                      className="border-0 list-group-item p-0  text-right"
                                      key={index}
                                    >
                                      {currencySymbolLeft()}
                                      {formatPrice(item)}
                                      {currencySymbolRight()}
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        </div>
                        <hr className="custom-hr" />
                        {/* total price */}
                        <div className="d-flex justify-content-between">
                          <div className="text-capitalize  report-title w-33 text-left">
                            total item sales
                          </div>
                          <div className="report-title w-33 text-center">
                            {xYreportData && xYreportData['categories_total_length']}
                          </div>
                          <div className="report-title w-33 text-right">
                            {currencySymbolLeft()}
                            {xYreportData && formatPrice(xYreportData['categories_total_price'])}
                            {currencySymbolRight()}
                          </div>
                        </div>
                        <hr className="custom-hr" />

                        {/********************************
                           * payemnts
                           * ******************************/}
                        <div className="d-flex justify-content-between">
                          <span className="report-title">Payments</span>
                        </div>
                        <div className="d-flex justify-content-between">
                          {/* payemnts name*/}
                          <div className="list-group w-33">
                            <div
                              className="border-0 list-group-item p-0  text-left"
                            >
                              cash
                            </div>
                            <div
                              className="border-0 list-group-item p-0 text-left"
                            >
                              card
                            </div>
                          </div>
                          {/* payment quantity */}
                          <div className="list-group w-33">
                            <div
                              className="border-0 list-group-item p-0  text-center"
                            >
                              {xYreportData && xYreportData['total_cash_count'] && xYreportData['total_cash_count']}
                            </div>
                            <div
                              className="border-0 list-group-item p-0  text-center"
                            >
                              {xYreportData && xYreportData['total_card_count'] && xYreportData['total_card_count']}
                            </div>
                          </div>
                          {/* payments price */}
                          <div className="list-group w-33">
                            <div
                              className="border-0 list-group-item p-0  text-right"
                            >
                              {currencySymbolLeft()}
                              {xYreportData && xYreportData['cash_total'] && formatPrice(xYreportData['cash_total'])}
                              {currencySymbolRight()}
                            </div>
                            <div
                              className="border-0 list-group-item p-0 text-right"
                            >
                              {currencySymbolLeft()}
                              {xYreportData && xYreportData['card_total'] && formatPrice(xYreportData['card_total'])}
                              {currencySymbolRight()}
                            </div>
                          </div>
                        </div>
                        <hr className="custom-hr" />
                        {/* payments total price */}
                        <div className="d-flex justify-content-between">
                          <div className="text-capitalize  report-title w-33 text-left">
                            total
                          </div>
                          <div className="report-title w-33 text-center">
                            {xYreportData && xYreportData['total_payment_count'] && xYreportData['total_payment_count']}
                          </div>
                          <div className="report-title w-33 text-right">
                            {currencySymbolLeft()}
                            {xYreportData && xYreportData['total_payment'] && formatPrice(xYreportData['total_payment'])}
                            {currencySymbolRight()}
                          </div>
                        </div>
                        <hr className="custom-hr" />
                        {/********************************
                           * department items
                           * ******************************/}
                        <div className="d-flex justify-content-between">
                          <span className="report-title">
                            department wise
                          </span>
                        </div>
                        {/* dept tags name */}
                        <div className="d-flex justify-content-between mt-4">
                          <div className="list-group w-33">
                            {xYreportData && xYreportData['dep_tags'] && xYreportData['dep_tags'].map((item, index) => {
                              return (
                                <div
                                  className="border-0 list-group-item p-0  text-left"
                                  key={index}
                                >
                                  {item}
                                </div>
                              );
                            })}
                          </div>
                          {/* dept tags length */}
                          <div className="list-group w-33">
                            {xYreportData && xYreportData['dept_items_length'] && xYreportData['dept_items_length'].map(
                              (item, index) => {
                                return (
                                  <div
                                    className="border-0 list-group-item p-0 text-center"
                                    key={index}
                                  >
                                    {item}
                                  </div>
                                );
                              }
                            )}
                          </div>
                          {/* dept items price */}
                          <div className="list-group w-33">
                            {xYreportData && xYreportData['dept_items_price'] && xYreportData['dept_items_price'].map((item, index) => {
                              return (
                                <div
                                  className="border-0 list-group-item p-0  text-right"
                                  key={index}
                                >
                                  {currencySymbolLeft()}
                                  {formatPrice(item)}
                                  {currencySymbolRight()}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <hr className="custom-hr" />
                        {/* dept totals */}
                        <div className="d-flex justify-content-between">
                          <div className="text-capitalize report-title w-33 text-left">
                            total sales
                          </div>
                          <div className="w-33 text-center"> {xYreportData && xYreportData['dept_items_total_length'] && xYreportData['dept_items_total_length']}</div>
                          <div className="report-title w-33 text-right">
                            {currencySymbolLeft()}
                            {xYreportData && xYreportData['total_dep_price'] && formatPrice(xYreportData['total_dep_price'])}
                            {currencySymbolRight()}
                          </div>
                        </div>
                        <hr className="custom-hr" />

                        {/********************************
                           * single items category
                           * ******************************/}
                        <div className="d-flex justify-content-between">
                          <span className="report-title">items </span>
                        </div>
                        {xYreportData && xYreportData['items'] && xYreportData['items'].map((items, index) => {
                          return (
                            <div className="d-flex justify-content-between">
                              <div className="text-capitalize w-33 text-left">
                                {items.food_item}
                              </div>
                              <div className="text-capitalize w-33 text-center">
                                {items.quantity}
                              </div>
                              <div className="text-capitalize w-33 text-right">
                                {currencySymbolLeft()}
                                {formatPrice(items.price)}
                                {currencySymbolRight()}
                              </div>
                            </div>
                          );
                        })}
                        <hr className="custom-hr" />
                        <div className="d-flex justify-content-between mb-4">
                          <div className="report-title w-33 text-left">total sales</div>

                          <div className="report-title w-33 text-center">
                            {xYreportData && xYreportData['total_items_quantity'] && xYreportData['total_items_quantity']}
                          </div>
                          <div className="report-title w-33 text-right">
                            {currencySymbolLeft()}
                            {xYreportData && xYreportData['total_items_price'] && formatPrice(xYreportData['total_items_price'])}
                            {currencySymbolRight()}
                          </div>
                        </div>

                        {/********************************
                           * total sumup
                           * ******************************/}
                        <div className="d-flex justify-content-between mt-4">
                          <div className="report-title">
                            total items sales
                          </div>
                          <div></div>
                          <div className="report-title">
                            {xYreportData &&
                              xYreportData["total_items_quantity"] &&
                              xYreportData["total_items_quantity"]}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="report-title">total sales</div>
                          <div></div>
                          <div className="report-title">
                            {currencySymbolLeft()}
                            {xYreportData && xYreportData['total_items_price'] && formatPrice(xYreportData['total_items_price'])}
                            {currencySymbolRight()}
                          </div>
                        </div>
                        <hr className="custom-hr" />
                        <div className="d-flex justify-content-between">
                          <div className="report-title">
                            Report printed on:
                          </div>
                          <div></div>
                          <div className="report-title">{todayDate}</div>
                        </div>
                        <hr className="custom-hr" />

                        {/* will come more */}
                      </div>
                    </div>
                  ]}
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default Xreport;
