import React, { useState, useContext, useEffect } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
//functions
import { _t, getCookie, pageLoading } from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL, demo } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FrontendDisable = () => {
  const { t } = useTranslation();

  // loading
  const [loading, setloading] = useState(true);

  // show website
  const [showWebsite, setshowWebsite] = useState(false);

  // get stripe info
  const getWebsiteShowOptions = () => {
    setloading(true);
    const url = BASE_URL + `/settings/get-show-website`;
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        console.log("this is from disable website data", res);
        if (res.data === "YES") {
          setshowWebsite(true);
        } else {
          setshowWebsite(false);
        }
        setloading(false);
      });
  };

  //useEffect == componentDidMount
  useEffect(() => {
    getWebsiteShowOptions();
  }, []);

  // handle submit
  const handleFrontendDisable = (e) => {
    e.preventDefault();
    // setshowWebsite(e.target.checked);

    const url = BASE_URL + `/settings/show-website`;
    let data = {
      show: showWebsite,
    };
    return axios
      .post(url, data, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        toast.success(`${_t(t("Settings saved successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        toast.error(`${_t(t("Try again!!"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Frontend settings"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0 ">
              <div className="t-bg-white p-3 p-md-5 height-80vh d-flex align-items-center">
                {loading == true ? (
                  pageLoading()
                ) : (
                  <>
                    <div class="container">
                      <form onSubmit={handleFrontendDisable}>
                        <div class="row justify-content-center">
                          <div class="col-lg-6 text-center">
                            <h4 className="text-secodary my-4 text-uppercase">
                              Click here to{" "}
                              {showWebsite === true ? "enable" : " disable"} the
                              website
                            </h4>
                            <hr />

                            <FormControlLabel
                              control={
                                <Switch
                                  checked={showWebsite}
                                  onChange={(event) => {
                                    setshowWebsite(event.target.checked);
                                  }}
                                />
                              }
                              label={`${
                                showWebsite === false ? "Enabled" : "Disabled"
                              }`}
                            />
                            <button
                              className="btn btn-success mt-4 w-100 text-capitalize"
                              type="submit"
                            >
                            {_t(t("save settings"))}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default FrontendDisable;
